import React, { useEffect, useState } from "react";
import axios from "axios";
import Gateway from "./Gateway";

export const Sitesettings = () => {
  const [WebTitle, setWebTitle] = useState("");
  const [WebsiteName, setWebName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [CompanyWebsite, setCompanyWebsite] = useState("");
  const [Logo, setLogo] = useState("");
  const [SmallLogo, setSmallLogo] = useState("");
  const [LandingImage1, setLandingImage1] = useState("");
  const [LandingImage2, setLandingImage2] = useState("");
  const [LandingImage3, setLandingImage3] = useState("");
  const [LandingImage4, setLandingImage4] = useState("");
  const [poster1, setPoster1] = useState("");
  const [poster2, setPoster2] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [refcom, setRefcom] = useState("");
  const [telegram, setTelegram] = useState("");
  const [insta, setInsta] = useState("");
  const [webmsg, setWebmsg] = useState("");

  const [ytlink, setYtlink] = useState("");
  const [despoitmsg, setDespoitmsg] = useState("");


  const [isLandingImage1, setIsLandingImage1] = useState(true);
  const [isLandingImage2, setIsLandingImage2] = useState(true);
  const [isLandingImage3, setIsLandingImage3] = useState(true);
  const [isLandingImage4, setIsLandingImage4] = useState(true);
  const [commission, setCommission] = useState('');
  const [upiid, setUpiid] = useState('');
  const [isdummy, setIsdummy] = useState(false);
  const [issignup, setIssignup] = useState(false);
  const [signupbonus, setSignupbonus] = useState(0);
  const [ytlinkdeposit, setYtlinkdeposit] = useState("");
  const [version, setVersion] = useState("");
  const [settingId, setSettingId] = useState("");

  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  useEffect(() => {
    axios.get(baseUrl + "settings/data")
      .then((res) => {
        const data = res.data;
        setSettingId(data._id || '');
        setWebTitle(data.WebTitle);
        setWebName(data.WebsiteName);
        setCompanyName(data.CompanyName);
        setCompanyAddress(data.CompanyAddress);
        setCompanyMobile(data.CompanyMobile);
        setCompanyEmail(data.CompanyEmail);
        setCompanyWebsite(data.CompanyWebsite);
        setLogo(data.Logo);
        setSmallLogo(data.SmallLogo);
        setLandingImage1(data.LandingImage1);
        setLandingImage2(data.LandingImage2);
        setLandingImage3(data.LandingImage3);
        setLandingImage4(data.LandingImage4);
        setYtlink(data.ytlink);
        setIsdummy(data.isdummy);
        setIssignup(data.issignup);
        setSignupbonus(data.signupbonus);
        setYtlinkdeposit(data.ytlinkdeposit);
        setPoster1(data.poster1);
        setPoster2(data.poster2);
        setQrcode(data.qrcode);
        setIsLandingImage1(data.isLandingImage1);
        setIsLandingImage2(data.isLandingImage2);
        setIsLandingImage3(data.isLandingImage3);
        setIsLandingImage4(data.isLandingImage4);
        setVersion(data.version);
        setCommission(data.commission);
        setUpiid(data.upiid);
        setInsta(data.insta);
        setTelegram(data.telegram);
        setWebmsg(data.websitemsg);
        setRefcom(data.refcom);
    setDespoitmsg(data.despoitmsg);
      });
  }, [baseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("WebTitle", WebTitle);
    formData.append("WebsiteName", WebsiteName);
    formData.append("CompanyName", CompanyName);
    formData.append("CompanyAddress", CompanyAddress);
    formData.append("CompanyMobile", CompanyMobile);
    formData.append("CompanyEmail", CompanyEmail);
    formData.append("CompanyWebsite", CompanyWebsite);
    formData.append("Logo", Logo);
    formData.append("SmallLogo", SmallLogo);
    formData.append("LandingImage1", LandingImage1);
    formData.append("LandingImage2", LandingImage2);
    formData.append("LandingImage3", LandingImage3);
    formData.append("LandingImage4", LandingImage4);
    formData.append("poster1", poster1);
    formData.append("poster2", poster2);
    formData.append("qrcode", qrcode);
    formData.append("isLandingImage1", isLandingImage1);
    formData.append("isLandingImage2", isLandingImage2);
    formData.append("isLandingImage3", isLandingImage3);
    formData.append("isLandingImage4", isLandingImage4);
    formData.append("version", version);
    formData.append("commission", commission);
    formData.append("upiid", upiid);
    formData.append("ytlink", ytlink);
    formData.append("websitemsg", webmsg);
    formData.append("insta", insta);
    formData.append("telegram", telegram);
    formData.append("refcom", refcom);
    formData.append("isdummy", isdummy);
    formData.append("issignup", issignup);
    formData.append("signupbonus", signupbonus);
    formData.append("ytlinkdeposit", ytlinkdeposit);
    formData.append("despoitmsg", despoitmsg);
    

    const response = await axios.post(baseUrl + "settings", formData);
    if (response.data.status === 'success') {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  useEffect(() => {
    const Logo1 = document.getElementById("Logo");
    const Logo2 = document.getElementById("SmallLogo");
    const LandingImage1 = document.getElementById("LandingImage1");
    const LandingImage2 = document.getElementById("LandingImage2");
    const LandingImage3 = document.getElementById("LandingImage3");
    const LandingImage4 = document.getElementById("LandingImage4");
    const poster1 = document.getElementById("poster1");
    const poster2 = document.getElementById("poster2");
    const qrcode = document.getElementById("qrcode");

    Logo1.onchange = (e) => setLogo(Logo1.files[0]);
    Logo2.onchange = (e) => setSmallLogo(Logo2.files[0]);
    LandingImage1.onchange = (e) => setLandingImage1(LandingImage1.files[0]);
    LandingImage2.onchange = (e) => setLandingImage2(LandingImage2.files[0]);
    LandingImage3.onchange = (e) => setLandingImage3(LandingImage3.files[0]);
    LandingImage4.onchange = (e) => setLandingImage4(LandingImage4.files[0]);
    poster1.onchange = (e) => setPoster1(poster1.files[0]);
    poster2.onchange = (e) => setPoster2(poster2.files[0]);
    qrcode.onchange = (e) => setQrcode(qrcode.files[0]);
  }, []);

  return (
    <>
      <h3 className="text-uppercase font-weight-bold my-3 text-white">Website Settings</h3>
      <h4 className="text-uppercase font-weight-bold my-3 text-light">UI Settings</h4>
      <form onSubmit={handleSubmit} method="patch" encType="multipart/form-data" style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Website Title</label>
            <input className="form-control" type="text" value={WebTitle} onChange={(e) => setWebTitle(e.target.value)} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Website Name</label>
            <input className="form-control" type="text" value={WebsiteName} onChange={(e) => setWebName(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="CompanyName">Company Name</label>
            <input className="form-control" type="text" value={CompanyName} onChange={(e) => setCompanyName(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="CompanyAddress">Company Address</label>
            <input className="form-control" type="text" value={CompanyAddress} onChange={(e) => setCompanyAddress(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="CompanyMobile">Company Mobile</label>
            <input className="form-control" type="text" value={CompanyMobile} onChange={(e) => setCompanyMobile(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="CompanyEmail">Company Email</label>
            <input className="form-control" type="text" value={CompanyEmail} onChange={(e) => setCompanyEmail(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="CompanyWebsite">Company Website</label>
            <input className="form-control" type="text" value={CompanyWebsite} onChange={(e) => setCompanyWebsite(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="CompanyWebsite">despoite Message</label>
            <input className="form-control" type="text" value={despoitmsg} onChange={(e) => setDespoitmsg(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="Logo">Logo</label>
            <input className="form-control" type="file" id="Logo" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="SmallLogo">Small Logo</label>
            <input className="form-control" type="file" id="SmallLogo" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="LandingImage1">Landing Image 1</label>
            <input className="form-control" type="file" id="LandingImage1" />
            <select className="form-control" value={isLandingImage1} onChange={(e) => setIsLandingImage1(e.target.value)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="LandingImage2">Landing Image 2</label>
            <input className="form-control" type="file" id="LandingImage2" />
            <select className="form-control" value={isLandingImage2} onChange={(e) => setIsLandingImage2(e.target.value)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="LandingImage3">Landing Image 3</label>
            <input className="form-control" type="file" id="LandingImage3" />
            <select className="form-control" value={isLandingImage3} onChange={(e) => setIsLandingImage3(e.target.value)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="LandingImage4">Landing Image 4</label>
            <input className="form-control" type="file" id="LandingImage4" />
            <select className="form-control" value={isLandingImage4} onChange={(e) => setIsLandingImage4(e.target.value)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="poster1">Poster 1</label>
            <input className="form-control" type="file" id="poster1" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="poster2">Poster 2</label>
            <input className="form-control" type="file" id="poster2" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="qrcode">QR Code</label>
            <input className="form-control" type="file" id="qrcode" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="version">Version</label>
            <input className="form-control" type="text" value={version} onChange={(e) => setVersion(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="commission">Commission</label>
            <input className="form-control" type="text" value={commission} onChange={(e) => setCommission(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="upiid">UPI ID</label>
            <input className="form-control" type="text" value={upiid} onChange={(e) => setUpiid(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="ytlink">YouTube Link</label>
            <input className="form-control" type="text" value={ytlink} onChange={(e) => setYtlink(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="webmsg">Website Message</label>
            <input className="form-control" type="text" value={webmsg} onChange={(e) => setWebmsg(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="insta">Instagram</label>
            <input className="form-control" type="text" value={insta} onChange={(e) => setInsta(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="telegram">Telegram</label>
            <input className="form-control" type="text" value={telegram} onChange={(e) => setTelegram(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="refcom">Referral Commission</label>
            <input className="form-control" type="text" value={refcom} onChange={(e) => setRefcom(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="isdummy">Is Dummy</label>
            <select className="form-control" value={isdummy} onChange={(e) => setIsdummy(e.target.value)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="issignup">Is Signup Bonus</label>
            <select className="form-control" value={issignup} onChange={(e) => setIssignup(e.target.value)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="signupbonus">Signup Bonus</label>
            <input className="form-control" type="text" value={signupbonus} onChange={(e) => setSignupbonus(e.target.value)} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="ytlinkdeposit">YouTube Link for Deposit</label>
            <input className="form-control" type="text" value={ytlinkdeposit} onChange={(e) => setYtlinkdeposit(e.target.value)} />
          </div>
        </div>
        <button className="btn btn-primary mt-3 mb-5" type="submit">Submit</button>
      </form>
      <Gateway />
    </>
  );
};

export default Sitesettings;
